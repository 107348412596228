import React from "react";
import "../Styles/Commonstyles.css";
import Hero from "./Hero";
import Button from "../Atoms/Button";
//import Typewriter from "typewriter-effect";

function Landing() {
  return (
    <div className="section">
      <h1>Brisbane Self-Service Garage</h1>
      <div>
        <button className="red-button">
          <h2>HOIST HIRE $30</h2>
        </button>
      </div>
      <div>
        <button className="white-button">
          <h2>STEAM CAR WASH $50</h2>
        </button>
      </div>
      <div>
        <button className="black-button">
          <h2>OIL CHANGE $50</h2>
        </button>
      </div>
    </div>
  );
}

export default Landing;
