import React from "react";
import "../Styles/Commonstyles.css";

function SectionTile() {
  return (
    <>
      <div className="tile">
        <h1>Our Services</h1>
      </div>
    </>
  );
}

export default SectionTile;
