import React from "react";
import "../Styles/Commonstyles.css";

function SectionTileThr() {
  return (
    <>
      <div className="tile3">
        <h1>ContactUs</h1>
      </div>
    </>
  );
}

export default SectionTileThr;
