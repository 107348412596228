import React from "react";
import "../Styles/Info.css";

const Info = () => {
  return (
    <div className="info-container">
      <div className="info"></div>
    </div>
  );
};

export default Info;
