import React from "react";
import SectionTile from "../Molecules/SectionTile";
import Brand from "../Molecules/Brand";
import FFooter from "../Molecules/FFooter";
import Pricing from "../Molecules/Pricing";
import PricingSteam from "../Molecules/PricingSteam";

const Services = () => {
  return (
    <div>
      <SectionTile />
      <Pricing />
      <PricingSteam />
      <Brand />
      <FFooter />
    </div>
  );
};
export default Services;
