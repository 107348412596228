import React from "react";
import { PricingTable, PricingSlot, PricingDetail } from "react-pricing-table";

import "../Styles/Pricing.css";

function Pricing() {
  return (
    <div className="pricing-section">
      <h1 className="pricing-h1">Hire Hoist</h1>
      <p>***We have Ongoing promotion BOOK NOW!***</p>
      <PricingTable highlightColor="#1976D2">
        <PricingSlot title="LONGER HOUR" priceText="$LONG PERIOD BOOKING">
          <PricingDetail>
            {" "}
            <b>CALL US TODAY</b>
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>FREE ENGINE OIL</b> *** we provide 100% full synthetic oil ***
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Unlimited</b> time
          </PricingDetail>
          <PricingDetail strikethrough>
            {" "}
            <b>Time tracking</b>
          </PricingDetail>
        </PricingSlot>
        <PricingSlot highlighted title="BASIC 30MIN" priceText="$30">
          <PricingDetail>
            {" "}
            <b>30 minutes renting hoist</b>
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>FREE 5L ENGINE OIL</b> *** we provide 100% full synthetic oil ***
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Unlimited</b> time
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Time tracking</b>
          </PricingDetail>
        </PricingSlot>
        <PricingSlot title="1HOUR" priceText="$50">
          <PricingDetail>
            {" "}
            <b>60 minutes renting hoist</b>
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>FREE 5L ENGINE OIL</b> *** we provide 100% full synthetic oil ***
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Unlimited</b> users
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Time tracking</b>
          </PricingDetail>
        </PricingSlot>
        <PricingSlot title="2HOUR" priceText="$100">
          <PricingDetail>
            {" "}
            <b>60 minutes renting hoist</b>
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>FREE 5L ENGINE OIL</b> *** we provide 100% full synthetic oil ***
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Unlimited</b> users
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Time tracking</b>
          </PricingDetail>
        </PricingSlot>
      </PricingTable>
    </div>
  );
}

export default Pricing;
