import "./App.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Home from "./components/Pages/Home";
import Services from "./components/Pages/Services";
import AboutUs from "./components/Pages/AboutUs";
import ContactUs from "./components/Pages/ContactUs";
import Navbar from "./components/Molecules/Navbar";

function App() {
  return (
    <Router>
      <Navbar />
      <div className="container">
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/services" component={Services}></Route>
          <Route path="/aboutus" component={AboutUs}></Route>
          <Route path="/contactus" component={ContactUs}></Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
