import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faBars } from "@fortawesome/free-solid-svg-icons";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import "../Styles/Commonstyles.css";
import logo from "../images/logo1.png";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <div classname="nav-img">
            <img
              src={logo}
              alt="logo"
              className="photo"
              height="55px"
              width="200px"
            />
          </div>
          <div exact to="/" className="nav-logo">
            <div className="nav-linker">
              <a href="tel:+61421464966">
                <FaPhoneAlt size="19" className="nav-linker" />
                0421 464 966
              </a>
            </div>
            <div className="nav-linker">
              <a href="https://goo.gl/maps/rc55DywgCJo26W28A">
                <FaMapMarkerAlt size="19" className="nav-linker " />
                60 Bellevue Ave Gaythorne QLD 4115
              </a>
            </div>
          </div>

          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                exact
                to="/"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="services"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                Servises
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="aboutus"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                AboutUs
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                exact
                to="/contactus"
                activeClassName="active"
                className="nav-links"
                onClick={handleClick}
              >
                ContactUs
              </NavLink>
            </li>
          </ul>

          <div className="nav-icon" onClick={handleClick}>
            <FontAwesomeIcon icon={click ? faTimes : faBars} />
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
