import React from "react";
import Card from "../Atoms/Card";
import "../Styles/Boxtile.scss";
import hoist from "../images/hoist.png";
import steam from "../images/steam.png";
import engineoil from "../images/engineoil.png";
import carwash from "../images/carwash.png";
import "../Styles/Commonstyles.css";

function Boxtile() {
  return (
    <>
      <div classname="card-container">
        <div className="boxtile-row">
          <Card
            title="What is Lorem Ipsum"
            images={hoist}
            old_price="999aud"
            newPrice="111aud"
            aud="$"
            alt="hoist"
            exp_date="25-10-2021"
          />
          <Card
            title="What is Lorem Ipsum"
            images={steam}
            old_price="999aud"
            newPrice="111aud"
            aud="$"
            alt="steam"
            exp_date="25-10-2021"
          />
          <Card
            title="What is Lorem Ipsum"
            images={engineoil}
            old_price="999aud"
            newPrice="111aud"
            aud="$"
            alt="engineoil"
            exp_date="25-10-2021"
          />
          <Card
            title="What is Lorem Ipsum"
            images={carwash}
            old_price="999aud"
            newPrice="111aud"
            aud="$"
            alt="carwash"
            exp_date="25-10-2021"
          />
        </div>
      </div>
    </>
  );
}

export default Boxtile;
