import React, { useState, useEffect } from "react";
import "../Styles/About.css";
import { FaGithub, FaCheck } from "react-icons/fa";
import backg from "../images/backg.jpg";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

function HowItWorkTwo(props) {
  const [image] = useState(props.image);
  const [heading] = useState(props.heading);
  const [show, setShow] = useState(props.show);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  });

  return (
    <div className="about-container">
      <div className="about-desc">
        <h3>{props.heading}</h3>
        <p>
          <ul>
            <li>
              <FaMapMarkerAlt className="aIcon" />
              60 Bellevue Ave Gaythorne 4051
            </li>
            <li>
              <FaPhoneAlt className="aIcon" />
              0421 464 966
            </li>
            <li>
              <MdEmail className="aIcon" />
              sangwoo0233@gmail.com
            </li>
          </ul>
        </p>
      </div>
      <div></div>
      <div className="about-img">
        <img src={backg} alt="about" height="300px" width="400px" />
      </div>
    </div>
  );
}

export default HowItWorkTwo;
