import React from "react";
import SectionTile from "../Molecules/SectionTile";
import HowItWork from "../Molecules/HowItWork";
import SectionTileTwo from "../Molecules/SectionTileTwo";
import HowItWorkTwo from "../Molecules/HowItWorkTwo";
import FFooter from "../Molecules/FFooter";

const AboutUs = () => {
  return (
    <div>
      <SectionTileTwo />
      <HowItWorkTwo />
      <FFooter />
    </div>
  );
};

export default AboutUs;
