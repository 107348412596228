import React, { useState } from "react";
import "../Styles/Form.css";
import FormSignup from "../Atoms/FormSignup";
import FormSuccess from "../Atoms/FormSuccess";
import svg from "../Atoms/img/img-3.svg";
import emailjs from "emailjs-com";

const ContactForm = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "gmail",
        "template_5nl6kul",
        e.target,
        "user_wkUU4kp8MUJXh8Nnx0thB"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <div className="con-section">
      <div className="form-container">
        <h3>Contact Us!</h3>
        <form className="register-form" onSubmit={sendEmail}>
          {/* Uncomment the next line to show the success message */}
          {/* <div className="success-message">Success! Thank you for registering</div> */}
          <h3></h3>
          <input
            id="first-name"
            className="form-field"
            type="text"
            placeholder="First Name"
            name="firstName"
          />
          {/* Uncomment the next line to show the error message */}
          {/* <span id="first-name-error">Please enter a first name</span> */}
          <input
            id="last-name"
            className="form-field"
            type="text"
            placeholder="Last Name"
            name="lastName"
          />
          <input
            id="phone number"
            className="form-field"
            type="text"
            placeholder="Mobile number"
            name="phone"
          />
          {/* Uncomment the next line to show the error message */}
          {/* <span id="last-name-error">Please enter a last name</span> */}
          <input
            id="email"
            className="form-field"
            type="text"
            placeholder="Email"
            name="email"
          />
          {/* Uncomment the next line to show the error message */}
          {/* <span id="email-error">Please enter an email address</span> */}
          <div>
            <h3>Car-rego</h3>
            <input
              id="car-rego number"
              className="form-field"
              type="text"
              placeholder="car-rego number"
              name="rego"
            />
          </div>
          <textarea className="form-field" name="message"></textarea>

          <button className="form-field" type="submit">
            SEND
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
