import React from "react";
import { PricingTable, PricingSlot, PricingDetail } from "react-pricing-table";

import "../Styles/Pricing.css";

function PricingSteam() {
  return (
    <div className="pricing-section">
      <h1 className="pricing-h1">Steam Cleaning</h1>
      <p>***We have Ongoing promotion BOOK NOW!***</p>
      <PricingTable highlightColor="#1976D2">
        <PricingSlot title="SELF CAR WASH" priceText="$50">
          <PricingDetail>
            {" "}
            <b>Car Seat steam</b>
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Car seat shampoo</b>
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Frendly member will teach you how to use steamer!</b>
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Free phytoncide disinfection</b>
          </PricingDetail>
        </PricingSlot>
        <PricingSlot highlighted title="STANDARD CAR" priceText="$120">
          <PricingDetail>
            {" "}
            <b>$120</b> *** Compact Standard Car ***
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Car Seat steam</b>
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Car seat shampoo</b>
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Free phytoncide disinfection</b>
          </PricingDetail>
        </PricingSlot>
        <PricingSlot title="COMPACT SUV" priceText="$130">
          <PricingDetail>
            {" "}
            <b>$130</b> *** Compact Standard Car ***
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Car Seat steam</b>
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Car seat shampoo</b>
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Free phytoncide disinfection</b>
          </PricingDetail>
        </PricingSlot>
        <PricingSlot title="LARGER SUV" priceText="$150">
          <PricingDetail>
            {" "}
            <b>$150</b> *** Compact Standard Car ***
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Car Seat steam</b>
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Car seat shampoo</b>
          </PricingDetail>
          <PricingDetail>
            {" "}
            <b>Free phytoncide disinfection</b>
          </PricingDetail>
        </PricingSlot>
      </PricingTable>
    </div>
  );
}

export default PricingSteam;
