import React, { useState, useEffect } from "react";
import "../Styles/About.css";
import { FaGithub, FaCheck } from "react-icons/fa";
//import img from "../images/hiw.jpg";
import img from "../images/cafe.png";

function BasicSection(props) {
  const [heading] = useState(props.heading);
  const [text] = useState(props.text);
  const [show, setShow] = useState(props.show);

  return (
    <div className="about-container2">
      <div className="about-desc">
        <h3>{props.heading}</h3>
        <p>{props.text}</p>
      </div>
      <div className="about-img">
        <img src={img} alt="about" height="300px" width="500px" />
      </div>
    </div>
  );
}

export default BasicSection;
