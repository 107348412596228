import React from "react";
import "../Styles/DoubleDiv.css";
function DoubleDiv() {
  return (
    <section className="info-ct">
      <div class="container">
        <div className="a">
          <h1>STEAM CLEANING</h1>

          <h3>***SELF STEAM CLEANING***</h3>
          <ul>
            <li>$50 – 30 minutes</li>
            <li>$90 – 1 hours</li>
            <li>
              Frendly Staff will guide you how to use Special steam cleaner
            </li>
            <li>
              <p>** Contact Us for Extra inquries** </p>
            </li>
          </ul>
        </div>
        <div className="b">
          <h1>RENT HOIST</h1>
          <h3>
            <strong>***UPDATED RATE***</strong>
          </h3>
          <ul>
            <li>$30 – 30 minutes</li>
            <li>$50 – 1 hours</li>
            <li>$100 – 2 hours</li>
          </ul>
          <p>** Contact Us for Extra inquries** </p>
        </div>

        <div className="c">
          <h1>EXTRAS</h1>
          <h3>***Additional charges may apply***</h3>
          <ul>
            <li>Remove OilFilm</li>
            <li>Head Lights restoration</li>
            <li>Engine bay steam cleaned</li>
            <li>Interior Steam Detailing</li>
          </ul>
        </div>
        <div className="d">
          <h1>PROMOTION</h1>
          <h3>***BOOK US TODAY!***</h3>
          <ul>
            <li>***HOIST HIRE $30***</li>
            <li>***HOIST HIRE $50***</li>
            <li>ENGINE OIL CHANGES $50</li>
            <li>FULL TEAM DETAILING $185</li>
            <li>INTERIOR STEAM DETAILING $145</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default DoubleDiv;
