import React from "react";
import ContactForm from "../Molecules/ContactForm";
import SectionTileThr from "../Molecules/SectionTileThr";
import FFooter from "../Molecules/FFooter";

const ContactUs = () => {
  return (
    <div>
      <SectionTileThr />
      <ContactForm />
      <FFooter />
    </div>
  );
};

export default ContactUs;
