import React from "react";
import "../Styles/Commonstyles.css";

function SectionTileTwo() {
  return (
    <>
      <div className="tile2">
        <h1>AboutUs</h1>
      </div>
    </>
  );
}

export default SectionTileTwo;
