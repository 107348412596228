import React, { useState, useEffect } from "react";
import "../Styles/About.css";
import { FaGithub, FaCheck } from "react-icons/fa";
//import img from "../images/hiw.jpg";

function HowItWork(props) {
  const [image] = useState(props.image);
  const [heading] = useState(props.heading);
  const [show, setShow] = useState(props.show);

  useEffect(() => {
    setTimeout(() => {
      setShow(false);
    }, 3000);
  });

  return (
    <div className="about-container">
      <div className="about-desc">
        <h3>{props.heading}</h3>
        <p>
          <ul>
            <li>
              <FaCheck className="aIcon" />
              Hoist rental
            </li>
            <li>
              <FaCheck className="aIcon" />
              Hoist rental
            </li>
            <li>
              <FaCheck className="aIcon" />
              Hoist rental
            </li>
            <li>
              <FaCheck className="aIcon" />
              Hoist rental
            </li>
            <li>
              <FaCheck className="aIcon" />
              Hoist rental
            </li>
          </ul>
        </p>
      </div>
      <div></div>
      <div className="about-img">
        <img src={props.image} alt="about" height="300px" width="400px" />
      </div>
    </div>
  );
}

export default HowItWork;
