import React from "react";
import "../Styles/AboutUsTile.scss";

function AboutUsTile() {
  return (
    <div className="about-main">
      <div className="jumbotron">
        <div className="jumbotron-inner">
          <div className="top-box">
            <div className="content-box">
              <h1 className="about-head-h1">
                <span className="about-span">DIY MOTORS</span>
              </h1>

              <p>
                DIY motors automotive expertise with an emphasis on high quality
                work and excellent customer service.
                <br />
              </p>
              <p>
                We’re a locally owned staffed by professionals who care about
                the needs of both you, and your vehicle.
              </p>
            </div>
          </div>
        </div>
        <div className="img-layer-container">
          <div className="team-image">
            <img src="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsTile;
