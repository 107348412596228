import React from "react";
import "../Styles/Footer.css";
import Boxtile from "../Molecules/Boxtile";

const Footer = () => {
  return (
    <section className="footer">
      <div className="footer-info">
        <h1>SELF-SERVICE GARAGE.</h1>
        <p>
          Open to the general public and small businesses, we have the
          commercial space you need to perform mechanical repairs and
          Professional car steam cleaning, detailing,Removing Oil film, paint
          protection, Head lights restoration.
        </p>
      </div>
      <div className="footer-contact">
        <h3>Contact me</h3>
        <p>
          Located conveniently in Gaythorne, only a <strong>10 MINUTES</strong>{" "}
          from the CBD. With ample parking, secure vehicle storage space if you
          need to leave your car overnight, and social and relaxing spaces, our
          first workshop is ready for your ride.
        </p>
      </div>
    </section>
  );
};

export default Footer;
