import React from "react";
import "../Styles/FFooter.css";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { RiKakaoTalkFill } from "react-icons/ri";
import logo from "../images/logo1.png";

function FFooter() {
  return (
    <section className="ff-section">
      <footer className="footer-distributed">
        <div className="footer-left">
          <img
            src={logo}
            alt="logo"
            className="photo"
            height="55px"
            width="200px"
          />

          <p className="footer-links">
            <a href="/#" className="link-1">
              Home
            </a>

            <a href="/#">Blog</a>

            <a href="/#">Pricing</a>

            <a href="/#">About</a>

            <a href="/#">Contact</a>
          </p>

          <p className="footer-company-name">DIY MOTORS PTY LTD © 2021</p>
        </div>

        <div className="footer-center">
          <div>
            <p>
              <span>60 Bellevue Ave</span> Gaythorne, QLD
            </p>
          </div>

          <div>
            <p>0421 464 966</p>
          </div>

          <div>
            <p>
              <a href="sangwoo0233@gmail.com">sangwoo0233@gmail.com</a>
            </p>
          </div>
        </div>

        <div className="footer-right">
          <p className="footer-company-about">
            <span>Our Social Network</span>
          </p>

          <div className="footer-icons">
            <a href="/#">
              <FaFacebook />
            </a>
            <a href="/#">
              <FaInstagram />
            </a>
            <a href="/#">
              <RiKakaoTalkFill />
            </a>
          </div>
        </div>
      </footer>
    </section>
  );
}

export default FFooter;
