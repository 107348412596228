import React from "react";
import "../Styles/Commonstyles.css";
import Boxtile from "../Molecules/Boxtile";
//import Services from "../Molecules/Services";
import HowItWork from "../Molecules/HowItWork";
import Info from "../Molecules/Info";
import Footer from "../Molecules/Footer";
import Landing from "../Molecules/Landing";
import AboutUsTile from "../Molecules/AboutUsTile";
import DoubleDiv from "../Molecules/DoubleDiv";
import FFooter from "../Molecules/FFooter";
import ScrollToTop from "../Atoms/ScrollToTop";
import BasicSection from "../Molecules/BasicSection";
import Brand from "../Molecules/Brand";
import img from "../images/hiw.jpg";

const Home = () => {
  return (
    <main>
      <Landing />
      <ScrollToTop />
      <AboutUsTile />
      <DoubleDiv />
      <HowItWork heading="What you need to bring for the day" image={img} />
      <Info />
      <Footer />
      <Brand />
      <BasicSection
        heading="Service your own vehicle by yourself"
        text="At DIY's, we make getting your car washed a stress-free, convenient stop along the way, letting you take a peaceful café "
      />
      <FFooter />
    </main>
  );
};

export default Home;
